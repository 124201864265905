<template>
  <div id="app" :class="currentRouteName" class="relative overflow-x-hidden">
    <div
      class="fondfit"
      v-if="
        currentRouteName === 'home' ||
        currentRouteName === 'wait' ||
        currentRouteName === 'home2'
      "
    ></div>
    <headerElem class="relative"></headerElem>
    <router-view class="relative" />
    <FooterElem class="relative"></FooterElem>

    <cookie-law storageName="cookies" class="!bg-white shadow-xl">
      <div class="cookie-banner" slot-scope="props">
        <div class="text-xl bg-white">
          Ce site web stocke des cookies sur votre ordinateur. Ces cookies sont
          utilisés pour collecter des informations sur la façon dont vous
          interagissez avec notre site Web et nous permettent de nous souvenir
          de vous. Nous utilisons ces informations afin d'améliorer et de
          personnaliser votre expérience de navigation et pour des analyses et
          des mesures sur nos visiteurs à la fois sur ce site Web et d'autres
          médias. Pour en savoir plus sur les cookies que nous utilisons,
          consultez les
          <a
            href="https://oxoformdocs.fra1.digitaloceanspaces.com/667400372f7d0b3305a4cd2f/politique.pdf"
            class="underline"
            target="_blank"
            rel="noopener"
            >politiques de confidentialité</a
          >. <br /><br />
          Si vous refusez, vos informations ne seront pas suivies lorsque vous
          visitez ce site Web. Un seul cookie sera utilisé dans votre navigateur
          pour mémoriser votre préférence de ne pas être suivi.
          <br />
          <br />
        </div>
        <div>
          <button class="skew button bleu !text-2xl" @click="props.accept">
            <span>J'accepte</span>
          </button>
          <button class="skew button gris !text-2xl ml-6" @click="props.close">
            <span>Je refuse</span>
          </button>
        </div>
      </div>
    </cookie-law>
  </div>
</template>
<script>
import HeaderElem from "@/components/HeaderElem";
import FooterElem from "@/components/FooterElem";
import CookieLaw from "vue-cookie-law";

export default {
  name: "App",
  mounted() {
    if (window.location.hostname == "ditesleavecbeghinsay.fr") {
      this.$router.push("/finish");
    }

    document.addEventListener("touchstart", function (e) {
      if (e.pageX > 10 && e.pageX < window.innerWidth - 10) return;
      e.preventDefault();
    });
  },

  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  components: {
    HeaderElem,
    FooterElem,
    CookieLaw,
  },
};
</script>
