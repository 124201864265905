<template>
  <main
    class="hd:max-w-[1500px] max-w-[1200px] px-10 desktop:p-4 mt-8 m-auto w-full"
  >
    <div
      v-if="popError"
      class="fixed bg-black/40 inset-0 h-full w-full z-40 flex justify-center items-center"
    >
      <div
        class="bg-red-100 w-full m-6 max-w-md p-6 rounded shadow-md text-red-600 text-center relative"
      >
        <i
          @click="popError = false"
          class="fa-solid fa-xmark absolute right-2 top-3 cursor-pointer"
        ></i>
        <p>{{ errorMessage }}</p>
      </div>
    </div>
    <div
      class="fixed w-full h-full inset-0 bg-black/70 z-50 flex justify-center items-center"
      v-if="load"
    >
      <div class="text-bleu bg-jaune py-4 px-6 rounded-md">
        Merci de patientez..<br />
        Validation en cours
      </div>
    </div>

    <div id="create">
      <img
        src="@/assets/images/pourparticiper.png"
        class="w-[200px] desktop:w-[250px] hd:w-80 m-auto -mt-4 desktop:-mt-12 mb-7"
      />
      <Form
        :class="'form_step' + step"
        id="monform"
        form_id="667400372f7d0b3305a4cd3e"
        server="https://api.odr.promo.dev"
        v-on:results="getResult"
        v-on:form_submit_loading="loading"
        @on_error="showError"
        :hideError="hiderror"
      />
      <div class="px-8 text-bleu text-xs mobile:text-sm text-center">
        *Etude OpinionWay du 6 au 7 mai 2024, 1097 répondants, catégorie sucre
      </div>
    </div>
    <simplert />
  </main>
</template>

<script>
import { Simplert } from "vue2-simplert-plugin";
import "vue2-simplert-plugin/dist/vue2-simplert-plugin.min.css";
import axios from "axios";

export default {
  name: "App",
  data() {
    return {
      hidden: true,
      load: false,
      step: 1,
      submited: false,
      hiderror: true,
      server: undefined,
      popError: false,
      errorMessage: undefined,
    };
  },
  components: {
    Simplert,
  },
  mounted() {
    if (window.location.hostname == "ditesleavecbeghinsay.fr") {
      this.$router.push("/finish");
    }
    sessionStorage.removeItem("id");
    sessionStorage.removeItem("uid");
    sessionStorage.removeItem("page");
    sessionStorage.removeItem("lot");
  },
  methods: {
    async getResult(result) {
      try {
        if (result) {
          console.log(result);
          if (result.status === "DEJA") {
            this.popError = true;
            this.load = false;
            this.errorMessage =
              "Une erreur interne est survenue. Merci de rééssayer plus tard.";
            return;
          }

          this.load = false;
          sessionStorage.setItem("id", result._id);
          sessionStorage.setItem("uid", result.uid);
          sessionStorage.setItem("page", 1);

          if (result.status == "GAGNE") {
            sessionStorage.setItem("lot", "sacatarte");
          } else {
            sessionStorage.setItem("lot", "br");
          }
          // eslint-disable-next-line no-undef

          let formDatas = {
            email: result.email,
          };

          const { data } = await axios.post(
            "https://gabapi.lucien.ai/api/operations-custom/2024-lonsdale-166/to_check",
            formDatas
          );

          if (data) {
            let tt = data?.count || 1;
            if (tt > 2) tt = 2;
            sessionStorage.setItem("page", tt);
            window.pagez = tt;
          }

          this.$router.push("/done");
        } else {
          this.error();
          this.load = false;
        }
      } catch (e) {
        this.error();
        this.load = false;
        console.log(e);
      }
    },
    loading() {
      this.load = true;
    },
    showError(event) {
      this.load = false;
      this.popError = true;
      if (
        event.error &&
        event.error.length > 0 &&
        (event.error[0] == "Email domain not allowed" ||
          event.error[0] == "Email address with + character not allowed")
      ) {
        this.errorMessage =
          "L'adresse email renseignée n'est pas valide : le domaine n'est pas accepté (exemple : @1cdds.ru) ou contient un +";
        return;
      }

      if (
        event.error &&
        event.error.message ==
          "Un ou plusieurs IMEI renseigné(s) est (sont) invalides ou a (ont) déjà été utilisé(s)"
      ) {
        this.errorMessage =
          "Un ou plusieurs IMEI renseigné(s) est (sont) invalides ou a (ont) déjà été utilisé(s)";
        return;
      }

      if (
        event.error &&
        event.error.message ==
          "Vous avez déjà participé 2 fois ! Vous ne pouvez tenter votre chance que 2 fois"
      ) {
        this.errorMessage =
          "Vous avez déjà participé 2 fois ! Vous ne pouvez tenter votre chance que 2 fois.";
        return;
      }

      if (event?.errorId) {
        this.errorMessage = event.errorId;
      } else {
        this.errorMessage = event?.message ?? event ?? "Erreur inconnue";
        if (window.posthog) {
          window.posthog.capture("form_error", {
            error: event,
          });
        }
      }
    },
    error() {
      alert("Une erreur est survenue, veuillez réessayer");
    },
  },
};
</script>

<style lang="scss">
#create {
  .form-step {
    @apply grid desktop:grid-cols-3 grid-cols-1 desktop:gap-8 text-bleu  justify-between;
  }

  .input_wrapper_gender {
    @apply order-1 desktop:order-none mb-2 desktop:mb-0;
  }

  .input_wrapper_last_name {
    @apply order-2 desktop:order-none mb-2 desktop:mb-0;
  }

  .input_wrapper_first_name {
    @apply order-3 desktop:order-none mb-2 desktop:mb-0;
  }

  .input_wrapper_address {
    @apply order-4 desktop:order-none mb-4 desktop:mb-0;
  }

  .input_wrapper_phone {
    @apply order-5 desktop:order-none mt-6 mb-4 desktop:mt-0 desktop:mb-0;
  }

  .input_wrapper_email {
    @apply order-6 desktop:order-none mb-4 desktop:mb-0;
  }

  .input_wrapper_receipt {
    @apply order-7 desktop:order-none mb-4 desktop:mb-0;
  }

  .input_wrapper_confirmation {
    @apply order-8 desktop:order-none mb-4 desktop:mb-0;
  }

  .input_wrapper_marketing_opt_in_1 {
    @apply order-9 desktop:order-none mb-4 desktop:mb-0;
  }

  .form_item {
    @apply border-2 px-4 py-1 rounded-md border-bleu relative mb-4 text-xl w-full;
  }

  label,
  input[type="text"],
  input[type="tel"],
  input[type="date"],
  input[type="email"],
  select {
    @apply inline-block w-full bg-transparent m-0 p-0 outline-none border-none focus:ring-0;
  }

  .step {
    display: none;
  }

  .marketing_opt_in_1_wrapper,
  .input_wrapper_gender .form_item,
  .input_wrapper_receipt,
  .confirmation_wrapper {
    @apply border-0;
  }

  .marketing_opt_in_1_wrapper,
  .confirmation_wrapper {
    @apply text-lg;
  }

  .boxes {
    @apply flex justify-start items-center p-0 m-0;
    input {
      @apply w-12 mr-2 h-12;
    }
  }

  .cannotfind {
    @apply text-base absolute left-0 -bottom-7;
    a {
      @apply underline;
    }
  }

  .mention {
    @apply hidden;
  }

  .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 100%x;
  }

  .upload-btn {
    background-color: white;
    padding: 0;

    font-size: 1px;
  }

  .upload-btn-wrapper {
    @apply absolute;
  }

  .upload-btn-wrapper input[type="file"] {
    font-size: 0px;
    position: absolute;
    left: -50px;
    top: 0;
    opacity: 1;
  }

  .required-star {
    @apply hidden;
  }

  .input_wrapper_receipt {
    @apply p-0 m-0;
  }

  label[for="receipt"] img {
    @apply w-[280px] -mt-2;
  }

  .upload-btn-set {
    @apply w-full text-base block text-center text-orange-400;
  }

  #confirmation_element,
  #marketing_opt_in_1_element {
    @apply flex flex-row-reverse items-start justify-start leading-5;
    input {
      @apply w-8 h-8 mr-3 rounded-md border-2 checked:bg-bleu border-bleu bg-transparent  outline-none focus:border-bleu focus:ring-0 text-bleu appearance-none;
    }
    a {
      @apply underline;
    }
  }

  #marketing_opt_in_1_element {
    @apply font-bold;
  }

  .info {
    @apply text-sm block mt-2;
  }

  .error {
    @apply text-red-500 text-sm leading-5 mt-2 block;
  }

  .grecaptcha-badge {
    visibility: hidden !important;
  }

  input[type="radio"] {
    @apply w-8 h-8 mr-3 rounded-full border-2 checked:bg-bleu border-bleu bg-transparent  outline-none focus:border-bleu focus:ring-0 text-bleu appearance-none;
  }
}

#create .form_item .form_item {
  border: none;
  padding: 0 !important;
}
</style>
